/* tslint:disable */

/**
 */
export class ReceiptForm {
    shifts?: number[];
    date?: string;
    date_to?: string;
    user_id?: number;
    vehicle_id?: number;
    route_id?:number;
    station_id?: number;
    time_start?: number;
    from_date?:any;
    to_date?:any;
    page?:number;
    limit?:number;
}
