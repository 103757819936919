/* tslint:disable */

/**
 */
export class QuanityFrom {
    from_date?: string;
    to_date?: string;
    route_id?: number;
    license_plates?: string;
    status?: number;
    limit?: number;
    page?: number;
}
