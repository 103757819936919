/* tslint:disable */

/**
 */
export class RpCardForm {
    from_date?: string;
    to_date?: string;
    type_search?: string;
    input_search?: string;

}
