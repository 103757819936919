/* tslint:disable */

/**
 */
export class ReceiptAllForm {
    shifts?: number[];
    date?: string;
    date_to?: string;
    user_id?: number;
    vehicle_id?: number;
    route_id?: number;
}
