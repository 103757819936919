import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, filter, map } from "rxjs/operators";
import { ApiConfiguration } from "../api-configuration";
import { BaseService } from "../base-service";
import { EInvoiceView } from '../models/einvoice-view';
import { QuanityFrom } from '../models/QuanityFrom';
import { QuanityGroupTrip} from '../models/QuanityGroupTrip';

@Injectable()
export class ManagerQuantityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }


/**
 * @param body - undefined
 */
 managerReportsViewQuantity(body?: any): Observable<any> {
  let token = localStorage.getItem('token');
  return (
    this.http
    .get(this.rootUrl + `/manager/reports/trips/view/`,{
    headers: {"Authorization": "Bearer "+token},

  })
  .pipe(catchError(this.handleError))
  )
}

managerReportsGroupQuantity(body: any): Observable<any> {
  let token = localStorage.getItem('token');
  return (
    this.http
    .post(this.rootUrl + `/manager/reports/trips/group/`,body,{
    headers: {"Authorization": "Bearer "+token},

  })
  .pipe(catchError(this.handleError))
  )
}

managerReportsQuanityResponse(body?: QuanityFrom): Observable<HttpResponse<EInvoiceView[]>> {
  let __params = this.newParams();
  let __headers = new HttpHeaders();
  let __body: any = null;
  // __body = body;
  if (body.from_date != null) __params = __params.set("from_date", body.from_date.toString());
  if(body.to_date != null) __params = __params.set("to_date", body.to_date.toString());
  if(body.license_plates != null) __params = __params.set("license_plates", body.license_plates.toString());
  if(body.route_id != null) __params = __params.set("route_id", body.route_id.toString());
  if(body.status != null) __params = __params.set("status", body.status.toString());
  if (body.page != null) __params = __params.set("page", body.page.toString());
  if (body.limit != null) __params = __params.set("limit", body.limit.toString());

  let req = new HttpRequest<any>(
    "GET",
    this.rootUrl + `/manager/reports/trips/view`,
    __body,
    {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

  return this.http.request<any>(req).pipe(
    filter(_r => _r instanceof HttpResponse),
    map(_r => {
      let _resp = _r as HttpResponse<any>;
      let _body: EInvoiceView[] = null;
      _body = _resp.body as EInvoiceView[]
      return _resp.clone({body: _body}) as HttpResponse<EInvoiceView[]>;
    })
  );
}
managerReportsQuanity(body?: QuanityFrom): Observable<EInvoiceView[]> {
  return this.managerReportsQuanityResponse(body).pipe(
    map(_r => _r.body)
  );
}

managerReportsGroupTripsResponse(body?: QuanityGroupTrip): Observable<HttpResponse<string>> {
  let __params = this.newParams();
  let __headers = new HttpHeaders();
  let __body: any = null;
  __body = body;
  let req = new HttpRequest<any>(
    "POST",
    this.rootUrl + `/manager/reports/trips/group`,
    __body,
    {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

  return this.http.request<any>(req).pipe(
    filter(_r => _r instanceof HttpResponse),
    map(_r => {
      let _resp = _r as HttpResponse<any>;
      let _body: string = null;
      _body = _resp.body as string;
      return _resp.clone({body: _body}) as HttpResponse<string>;
    })
  );
}

/**
 * @param body - undefined
 */
 managerReportsGroupTrips(body?: QuanityGroupTrip): Observable<string> {
  return this.managerReportsGroupTripsResponse(body).pipe(
    map(_r => _r.body)
  );
}

managerReportsExportTripResponse(data:any): Observable<HttpResponse<string>> {
  let __params = this.newParams();
  let __headers = new HttpHeaders();
  let __body: any = null;
  __body = data;
  let req = new HttpRequest<any>(
    "POST",
    this.rootUrl + `/manager/reports/trips/export`,
    __body,
    {
      headers: __headers,
      params: __params,
      responseType: 'blob',
    });

  return this.http.request<any>(req).pipe(
    filter(_r => _r instanceof HttpResponse),
    map(_r => {
      let _resp = _r as HttpResponse<any>;
      let _body: string = null;
      _body = _resp.body as string
      return _resp.clone({body: _body}) as HttpResponse<string>;
    })
  );
}

/**
 * @param body - undefined
 */
 managerReportsExportTrip(data:any): Observable<string> {
  return this.managerReportsExportTripResponse(data).pipe(
    map(_r => _r.body)
  );
}

managerReportsExportTripAllResponse(data:any): Observable<HttpResponse<string>> {
  let __params = this.newParams();
  let __headers = new HttpHeaders();
  let __body: any = null;
  __body = data;
  let req = new HttpRequest<any>(
    "POST",
    this.rootUrl + `/manager/reports/trips/export/all`,
    __body,
    {
      headers: __headers,
      params: __params,
      responseType: 'blob',
    });

  return this.http.request<any>(req).pipe(
    filter(_r => _r instanceof HttpResponse),
    map(_r => {
      let _resp = _r as HttpResponse<any>;
      let _body: string = null;
      _body = _resp.body as string
      return _resp.clone({body: _body}) as HttpResponse<string>;
    })
  );
}

/**
 * @param body - undefined
 */
 managerReportsExportTripAll(data:any): Observable<string> {
  return this.managerReportsExportTripAllResponse(data).pipe(
    map(_r => _r.body)
  );
}

managerReportsGetDetailTripsResponse(data:any): Observable<HttpResponse<string>> {
  let __params = this.newParams();
  let __headers = new HttpHeaders();
  let __body: any = null;
  __body = data;
  let req = new HttpRequest<any>(
    "POST",
    this.rootUrl + `/manager/reports/trips/detail`,
    __body,
    {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

  return this.http.request<any>(req).pipe(
    filter(_r => _r instanceof HttpResponse),
    map(_r => {
      let _resp = _r as HttpResponse<any>;
      let _body: string = null;
      _body = _resp.body as string
      return _resp.clone({body: _body}) as HttpResponse<string>;
    })
  );
}

/**
 * @param body - undefined
 */
 managerReportsGetDetailTrips(data:any): Observable<string> {
  return this.managerReportsGetDetailTripsResponse(data).pipe(
    map(_r => _r.body)
  );
}

handleError(error: HttpErrorResponse): Observable<any> {
  let errorMessage = 'Unknown error!';
  if (error.error instanceof ErrorEvent) {
    // Client-side errors
    errorMessage = `Error: ${error.error.message}`;
  } else {
    // Server-side errors
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  }
  // window.alert(errorMessage);
  console.log('Error', errorMessage);
  return throwError(errorMessage);
}
}

export module ManagerReportsService {
}
