/* tslint:disable */

/**
 */
export class TransactionDetailSearch {
    date_from?: string;
    date_to?: string;
    type_payment?: string;
    type_search?: string;
    input_search?: string;
}
