/* tslint:disable */

/**
 */
export class CompanyEInvoiceHilo {
  push_auto?: boolean;
  passenger_ticket?: boolean;
  goods_ticket?: boolean;
  url?: string;
  username?: string;
  password?: string;
  taxcode?: string;
  pattern?: string;
  serial?: string;
  push_data_centre?: boolean;
  vehicles_arr?: any;
  vehicles?: any;
  vehicles_date?: any;
}
