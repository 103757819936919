/* tslint:disable */

/**
 */
export class EInvoiceForm {
    from_date?: string;
    to_date?: string;
    user_id?: number;
    vehicle_id?: number;
    e_invoice_status?: number;
    page?:number;
    limit?:number;
}
